<template>
    <div class="loginwrap">
        <div class="backbox" @click="back">
            <span class="el-icon-arrow-left"></span>返回
        </div>
        <div class="loginform">
            <div class="logintype">
                <div class="registtitle">账户注册</div>
                <el-input class="inputform input_item" v-model="form.phone" placeholder="请输入账号/手机号">
                    <template slot="prepend"><span class="el-icon-user input_logo"></span></template>
                </el-input>
                <el-input class="inputform input_item" v-model="form.yzm" placeholder="请输入验证码">
                    <template slot="prepend"><span class="el-icon-lock input_logo"></span></template>
                    <el-button slot="append" @click="getyzm">{{yzmconfig.time == 0 ? '获取验证码' : yzmconfig.time + 's'}}</el-button>
                </el-input>
                <el-input class="inputform input_item" v-model="form.password1" placeholder="请输密码" type='password'>
                    <template slot="prepend"><span class="el-icon-lock input_logo"></span></template>
                </el-input>
                <el-input class="inputform input_item" v-model="form.password2" placeholder="请再次确认密码" type='password'>
                    <template slot="prepend"><span class="el-icon-lock input_logo"></span></template>
                </el-input>
                <el-button type="warning" round size='medium' class="loginbtn" @click="registbtn">注册</el-button>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>

<script>
import { getyzmapi , registapi } from '@/api/regist.js';
import { Message } from 'element-ui';
import md5 from 'md5';


export default {
    data() {
        return {
            form:{
                phone: '',
                yzm: '',
                password1: '',
                password2: '',
            },
            yzmconfig:{
                time: 0,
            },
        }
    },
    methods:{
        back(){
            this.$router.go(-1);
        },
        getyzm(){
            if(this.yzmconfig.time == 0){
                if(!this.form.phone){
                    return Message({
                             showClose: true,
                             message: '请填写手机号',
                             type: 'error'
                           });
                }
                getyzmapi({
                    owner_Phone: this.form.phone
                }).then((res) => {
                    if(res.state == '2600'){
                        this.yzmconfig.time = 60;
                        let yzmtime = setInterval(() => {
                            if(this.yzmconfig.time > 0){
                                this.yzmconfig.time = --this.yzmconfig.time;
                            }else{
                                clearInterval(yzmtime);
                            }
                        },1000)
                    }
                    else{
                        return Message({
                                 showClose: true,
                                 message: res.message,
                                 type: 'error'
                               });
                    }
                })
            }
        },
        registbtn(){
            if(!(this.form.phone&&this.form.yzm&&this.form.password1&&this.form.password2)){
                return Message({
                             showClose: true,
                             message: '请填写完整信息',
                             type: 'error'
                           });
            }
            if(this.form.password1!=this.form.password2){
                return Message({
                             showClose: true,
                             message: '两次输入密码不一致',
                             type: 'error'
                           });
            }
            let form = {
                wxPhone: this.form.phone,
                code: this.form.yzm,
                pswd: md5(this.form.password2),
                apartment_Sid: this.$store.state.project.projectid,
            }
            registapi(form).then((res) => {
                if(res.state == '2600'){
                    this.$router.push({path: '/login'})
                }
                else{
                    Message({
                      showClose: true,
                      message: res.message,
                      type: 'error'
                    });
                }
            })
        },
    }
}
</script>

<style scoped>

    .loginwrap{
        width: 100%;
        height: 100%;
        background-image: url("../../assets/img/login.jpg");
        background-size: 100% 100%;
        display: flex;
        align-items: center;
    }

    .backbox{
        position: absolute;
        top: 20px;
        left: 20px;
        color: #FFFFFF;
        font-weight: 800;
        cursor: pointer;
    }

    .el-icon-arrow-left{
        margin-right: 10px;
    }

    /* 表单 */
    .loginform{
        width: 300px;
        height: 400px;
        background-color: #FFFFFF;
        /* margin-left: 300px; */
        margin-left: 14%;
        padding: 20px 50px;
        font-size: 14px;
        border-radius: 12px;
    }

    .inputform{
        margin: 10px 0px;
    }
    
    .registtitle{
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 20px;
        font-weight: 800;
        margin-bottom: 20px;
    }

    .loginbtn{
        width: 100%;
        margin-top: 20px;
    }

    >>>.el-input__inner{
        border: none;
    }

    >>>.el-input-group__prepend,
    >>>.el-input-group__append{
        background-color: transparent;
        border: none;
        padding: 0px;
    }

    >>>.el-input-group__prepend,
    >>>.el-input-group__append{
        background-color: transparent;
        border: none;
        padding: 0px;
    }

    >>>.input_item{
        border-bottom: 1px solid #DCDFE6;
    }

    >>>.input_logo{
        font-size: 20px;
    }

    >>>.el-button--warning{
        background-color: #000000;
        border-color: #000000;
    }

</style>
